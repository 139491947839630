import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ButtonPrimary } from "../../../components/widgets/button";
import { ContentPlusDecor } from "../../../components/widgets/content-plus-decor";
import { FaUserFriends } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Refer a Friend`}</h1>
    <Breadcrumbs to="/company/" text="Company" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaUserFriends mdxType="FaUserFriends" />} mdxType="ContentPlusDecor">
      <p><em parentName="p">{`TuringTrader.com`}</em>{` is growing rapidly around an awesome nucleus of ambitious DIY investors, just like you. If you would like to share your passion for investing and participate in our growth, we invite you to join our referral program. We recognize your support with a 30% unlimited referral commission.`}</p>
      <h2>{`How It Works`}</h2>
      <p>{`Here is how you can get started and what you can expect: `}</p>
      <ol>
        <li parentName="ol"><a parentName="li" {...{
            "href": "/company/contact/"
          }}>{`Contact us`}</a>{` and let us know that you would like to join our referral program. Our program is open to all `}<em parentName="li">{`Premium`}</em>{` members in good standing. On a case-by-case basis, we also accept applications from bloggers, influencers, and affiliate marketers.`}</li>
        <li parentName="ol">{`Receive your personalized dashboard to generate referral links and track your success.`}</li>
        <li parentName="ol">{`Share your referral link with your friends. You get paid on all sales within 60 days of referring a new `}<em parentName="li">{`Premium`}</em>{` member to us.`}</li>
        <li parentName="ol">{`Receive a 30% commission on all membership fees paid by every new member you referred.`}</li>
        <li parentName="ol">{`Get paid as long as you are a `}<em parentName="li">{`Premium`}</em>{` member and your referred members pay subscription fees. There is no time limit and no total commission limit. However, you only receive commissions on your direct referrals, not on your referral's referrals.`}</li>
      </ol>
      <ButtonPrimary text="Join our Referral Program" to="/company/contact/" mdxType="ButtonPrimary" />
      <br /><br /><br />
      <h2>{`The Fine Print`}</h2>
      <p>{`In joining our program, you agree to the following:`}</p>
      <ol>
        <li parentName="ol">{`This is not a discount program and you cannot refer yourself.`}</li>
        <li parentName="ol">{`You may only use the referral links on your personal website, in direct communication, or in social media posts. `}</li>
        <li parentName="ol">{`You may not set up branded websites, send out mass emails, or use paid advertising to promote your referral link.`}</li>
        <li parentName="ol">{`You may not copy, resemble, or mirror the look and feel of our website or branding.`}</li>
        <li parentName="ol">{`You may not represent that your posts are endorsed or sponsored by `}<em parentName="li">{`TuringTrader.com`}</em>{` in any way.`}</li>
        <li parentName="ol">{`You may not manipulate the referral links obtained from the referral dashboard in any way.`}</li>
        <li parentName="ol">{`You understand that we are using cookies to track referrals and that this technology has limitations.`}</li>
        <li parentName="ol">{`We reserve the right to remove any suspicious commissions, remove affiliates, or place payments on hold on any affiliate that breaks these rules.`}
          <br /><br /><br />
        </li>
      </ol>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      